<template>
  <div>
    <vs-button
      @click="cadastrarFilialPopup = true"
      color="primary"
      class="mt-3"
      type="flat"
    >
      <b class="mr-2">Adicionar filial</b>
      <i class="onpoint-plus-circle"></i>
    </vs-button>

    <vs-popup
      class="cadastrar-filial"
      title=""
      :active.sync="cadastrarFilialPopup"
    >
      <h3 class="pl-4 text-primary mb-8">Adicionar nova filial</h3>
      <div class="p-4 pt-0 mb-8">
        <div class="vx-col">
          <vs-input
            label="Digite o CNPJ da filial"
            size="large"
            v-mask="'##.###.###/####-##'"
            class="w-full"
            v-model="cnpj"
          />
        </div>

        <div class="vx-col mt-5 text-right">
          <vs-button @click="salvarFilial()">
            Salvar filial
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";

import { mapActions } from "vuex";
import * as Utils from "@/utils/utils";
import instance from "@/axios";

export default {
  name: "adicionar-filial",
  props: ["uniqueIdTomador"],
  directives: { mask },
  data() {
    return {
      cadastrarFilialPopup: false,
      cnpj: "",
      cep: "",
      endereco: {},
      messagesError: []
    };
  },
  methods: {
    ...mapActions("filial-module", [
      "buscaFilialPorTexto",
      "validaFilialPorCnpj",
      "cadastrarFilial"
    ]),

    async salvarFilial() {
      await this.$onpoint.loadingModal(
        "Aguarde, as informações estao sendo processadas",
        async () => {
          this.cadastrarFilialPopup = false;
          const newFilial = {
            cnpj: Utils.removeSpecialChars(this.cnpj),
            uniqueId: this.uniqueIdTomador
          };
          return await this.cadastrarFilial(newFilial)
            .then(response => {
              this.$emit("modal_close", true);
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            })
            .finally(() => {
              this.cnpj = "";
            });
        }
      );
    },

    async buscarCep() {
      const cep = Utils.removeSpecialChars(this.cep);
      if (!cep) return;
      await this.$onpoint.loadingModal("Aguarde, buscando seu endereço", async() => {
        return await instance
          .get(`/api/Location/SearchCEP?cep=${cep}`)
          .then(response => {
            this.endereco = response.data.Response;
          });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.cadastrar-filial {
  ::v-deep .vs-popup--header {
    background: white;
  }

  ::v-deep .vs-popup {
    width: 800px !important;
  }
}
.onpoint-plus-circle::before{
  position: relative;
  top: 4px;
  font-size: 20px;
}
</style>
